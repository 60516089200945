import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../utils/axios/customBaseQuery';

const apiHost = process.env.REACT_APP_API_HOST;

export const kanbanService = createApi({
  reducerPath: 'kanbanService',
  baseQuery: axiosBaseQuery({
    baseUrl: apiHost,
    prepareHeaders: ({ getState }) => {
      const token = getState()?.auth.token;
      if (token) {
        return {
          Authorization: `Bearer ${token}`,
        };
      }
      return {};
    },
  }),
  tagTypes: ['Tasks'],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getTaskWithFilters: builder.query({
      query: ({ projectId }) => ({
        url: '/kanban/filter',
        method: 'POST',
        data: { projectId },
      }),
      providesTags: ['Tasks'],
    }),
    editTask: builder.mutation({
      query: ({ data, id }) => ({
        url: `/kanban/tasks/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Tasks'],
    }),
  }),
});

export const { useGetTaskWithFiltersQuery, useEditTaskMutation } = kanbanService;
