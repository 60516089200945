import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import { useState } from 'react';
import moment from 'moment';
import { useGetMasterDataQuery } from '../../../Redux/services/masterData';
import useDebounce from '../../custom/useDebounce';
import { useGlobalSearchQuery } from '../../../Redux/services/globalSearch';
import { dropdownConstants, reportCustomizableFieldsKeyMapper } from '../../../utils/constant';

const getCustomFieldsKey = (descriptiveKey) => reportCustomizableFieldsKeyMapper[descriptiveKey];

const customSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontWeight: '500',
    opacity: '.7',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '1.15rem',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0.7rem',
    paddingBottom: '0.7rem',
    borderRadius: '1.15rem',
    backgroundColor: 'var(--bs-dropdown-bg)',
    fontSize: '13px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--bs-dropdown-bg)',
    color: state.isSelected ? 'var(--bs-text-gray-800)' : 'var(--bs-gray-700)',
    ':hover': {
      backgroundColor: 'var(--bs-component-hover-bg)',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--bs-gray-300)',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontWeight: '500',
    color: 'var(--bs-gray-700)',
    fontSize: '1.1rem',
  }),
};
function ReportFilterFields({
  setFilter, customFields, filtersData, setCurrentPage,
}) {
  const { data: masterData } = useGetMasterDataQuery();
  const [taskSearch, setTaskSearch] = useState('');
  const [inputsData, setInputsData] = useState({});
  const [taskId, setTaskId] = useState('');
  const [title, setTitle] = useState('');
  const [project, setProject] = useState('');
  const [selectInputsData, setSelectInputsData] = useState({});

  const taskDebounced = useDebounce({ value: taskSearch, delay: 500 });
  const { data: globalSearchData } = useGlobalSearchQuery({
    data: { keyword: taskDebounced },
  });

  const handleInputChange = (key, value) => {
    // For if value is an array
    if (key === getCustomFieldsKey('taskUsers')
      || key === getCustomFieldsKey('taskStatus')
      || key === getCustomFieldsKey('projectUsers')
      || key === getCustomFieldsKey('projectStatus')) {
      setInputsData((prev) => ({ ...prev, [key]: value?.map((item) => item.value) }));
    } else if (key === getCustomFieldsKey('projectName')
      || key === getCustomFieldsKey('uniqueTaskId')
      || key === getCustomFieldsKey('title')
      || key === getCustomFieldsKey('daysToGo')
      || key === getCustomFieldsKey('projectPercentComplete')) {
      // For if value is an object
      setInputsData((prev) => ({ ...prev, [key]: value?.value }));
    } else {
      // For if value is a string
      setInputsData((prev) => ({ ...prev, [key]: value }));
    }
  };

  const getType = (val) => {
    if (val) {
      if (val.toLowerCase() === 'integer') {
        return 'number';
      }
      if (val.toLowerCase() === 'string') {
        return 'text';
      }
      return val;
    }
    return null;
  };

  const renderOptions = (label) => {
    if (label === 'Task No') {
      return globalSearchData?.data?.tasks?.map((task) => ({
        label: task?.uniqueTaskId,
        value: task?.uniqueTaskId,
      }));
    }

    if (label === 'Project Name') {
      return globalSearchData?.data?.projects?.map((projectData) => ({
        label: projectData?.name,
        value: projectData?.name,
      }));
    }

    if (label === 'Task Name') {
      return globalSearchData?.data?.tasks?.map((task) => ({
        label: task?.title,
        value: task?.title,
      }));
    }

    return null;
  };

  const handleClearFilters = () => {
    setFilter('');
    setInputsData('');
    setCurrentPage(1);
    setTaskId('');
    setProject('');
    setTitle('');
    setSelectInputsData('');
  };

  const handleTypaheadSelectChange = (key, value) => {
    if (key === getCustomFieldsKey('projectName')) {
      setProject(value);
    } else if (key === getCustomFieldsKey('title')) {
      setTitle(value);
    } else if (key === getCustomFieldsKey('uniqueTaskId')) {
      setTaskId(value);
    }
  };

  const handleTypaheadSelectValues = (key) => {
    if (key === getCustomFieldsKey('projectName')) {
      return project;
    } if (key === getCustomFieldsKey('title')) {
      return title;
    } if (key === getCustomFieldsKey('uniqueTaskId')) {
      return taskId;
    }
    return null;
  };

  const handleDatePickerCancel = (value, fieldName) => {
    handleInputChange(fieldName, value);
  };

  const renderSelect = ({
    options, key,
  }) => (
    <Select
      key={key}
      name="colors"
      className="filterSelect"
      classNamePrefix="select"
      value={selectInputsData[key] ?? []}
      options={options}
      isMulti={(key !== 'daysToGo' && key !== 'projectPercentComplete')}
      onChange={(val) => {
        setSelectInputsData((prev) => ({ ...prev, [key]: val }));
        handleInputChange(key, val);
      }}
      styles={customSelectStyles}
      isClearable
    />
  );

  const getFilterInputs = () => {
    const filters = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    Object.entries(customFields).forEach(([key, field]) => {
      if (!field?.filterChecked) {
        // eslint-disable-next-line no-param-reassign
        delete filtersData[key];
        return;
      }

      let options = [];
      if (masterData?.data) {
        if (key === getCustomFieldsKey('taskStatus')) {
          options = masterData?.data.taskStatuses?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        if (key === getCustomFieldsKey('taskUsers')
          || key === getCustomFieldsKey('projectUsers')) {
          options = masterData?.data.users?.map((item) => ({
            label: item?.fullName,
            value: item?.id,
          }));
        }
        if (key === getCustomFieldsKey('projectStatus')) {
          options = masterData?.data?.projectStatuses?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        if (key === getCustomFieldsKey('daysToGo')) {
          options = dropdownConstants.DAYS_TO_GO_OPTIONS;
        }
        if (key === getCustomFieldsKey('projectPercentComplete')) {
          options = dropdownConstants.PROJECT_PROGRESS_PERCENTAGE_OPTIONS;
        }

      }
      let filterElement;

      switch (field?.inputType?.toLowerCase()) {
        case 'select':
          filterElement = renderSelect({
            options,
            key,
          });
          break;
        case 'date':
          filterElement = (
            <DateRangePicker
              onApply={(e, j) => {
                handleInputChange(key, `${moment(j.startDate).format('YYYY/MM/DD')}-${moment(j.endDate).format('YYYY/MM/DD')}`);
              }}
              initialSettings={{
                startDate: new Date(),
                endDate: new Date(),
                maxDate: new Date(currentDate.getFullYear(), currentMonth + 1, 0),
                locale: { cancelLabel: 'Clear' },
              }}
              onCancel={() => { handleDatePickerCancel('', key); }}
            >
              <p className="form-control text-sm-left">
                {inputsData?.[key] ? inputsData?.[key] : 'Select date'}
              </p>
            </DateRangePicker>

          );
          break;
        case 'typeahead':
          filterElement = (
            <Select
              key={key}
              options={renderOptions(field?.label)}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={handleTypaheadSelectValues(key)}
              isClearable
              onChange={(val) => {
                handleTypaheadSelectChange(key, val);
                handleInputChange(key, val);
              }}
              styles={customSelectStyles}
              onInputChange={(input) => setTaskSearch(input)}
              placeholder="Search..."
            />
          );
          break;
        default:
          filterElement = (
            <input
              min={field.min ?? null}
              max={field.max ?? null}
              type={getType(field.type)}
              value={inputsData?.[key] ?? ''}
              className="form-control "
              onChange={(e) => {
                let { value } = e.target;
                if (field.type.toLowerCase() === 'integer' || field.type.toLowerCase() === 'number') value = parseInt(e.target.value, 10);
                handleInputChange(key, value);
              }}
            />
          );
      }

      filters.push(
        <div key={field.label} className="col-lg-3">
          <label className="form-label w-250px">{field.label === 'Project Name' || field.label === 'Project #' ? 'Project' : field.label}</label>
          {filterElement}
        </div>,
      );
    });
    return filters;
  };

  return (
    <div className="row gy-5 g-xl-10">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="card card-flush mb-5">
          <div className="card-header py-4 minimize">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-gray-800">Search Filters</span>
            </h3>
            <div className="card-toolbar mt-0">
              <button
                type="button"
                className="btn btn-icon btn-sm btn-light-primary justify-content-center minimize"
                aria-label="button"
              >
                <i className="fas fa-minus" />
              </button>
            </div>
          </div>

          <div className="card-body border-0 py-0 minimize reports-filter-section">
            {
                Object.values(customFields).filter((field) => field?.filterChecked).length > 0
                  ? (
                    <>
                      <div className="row mb-5 horizontal-scrollable">
                        {getFilterInputs()}
                      </div>
                      <div className="d-flex justify-content-end p-5 ">
                        <button
                          type="button"
                          onClick={() => {
                            const filters = { ...inputsData };
                            setFilter(filters);
                            setCurrentPage(1);
                          }}
                          className="btn btn-dark fs-6"
                        >Filter
                        </button>
                        <button onClick={handleClearFilters} type="button" className="btn btn-label-secondary">Clear</button>
                      </div>
                    </>
                  )
                  : <div className="d-flex justify-content-center pb-20">No Filters Selected</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportFilterFields;
